import * as React from 'react'
import { Row, Col } from 'react-bootstrap'
import { RichText } from 'prismic-reactjs'

const SectionTitle = ({title, id}) => {

    if(id) {
        return (
          <Row className="mt-4 mt-md-5 mb-4 mb-md-5 image-slider">
            <Col md={1} lg={2}></Col>
            <Col>
              <RichText
                field={title}
                components={{
                  heading2: ({ children }) => <h2 id={id}>{children}</h2>,
                }}
              />
            </Col>
            <Col md={1} lg={2}></Col>
          </Row>
        )
    } else {

        return (
            <Row className="mt-4 mt-md-5 mb-4 mb-md-5">
                <Col md={1} lg={2}></Col>
                <Col>
                    <RichText
                                    field={title}
                                    components={{
                                        heading2: ({ children }) => <h2 id={id}>{children}</h2>
                                    }}
                                />
                </Col>
                <Col md={1} lg={2}></Col>
            </Row>
        )
    }
}

export default SectionTitle
