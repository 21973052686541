import { createGlobalStyle } from 'styled-components'
import { darkenLighten, hexToRgbA } from './utils/ColorAdjust'

const pageColor = '#5e5faa'
const pageBgColor = '#c7c8ff'
const pageLinkColor = '#8b1a8b'
const pageBgColorDarker = darkenLighten(pageBgColor, -2)
const pageColorDarker = darkenLighten(pageColor, 100)
const pageBgColorDark = darkenLighten(pageBgColor, -10)
const pageColorDark = darkenLighten(pageColor, 90)
const pageLinkColorDarker = darkenLighten(pageLinkColor, -40)
const headerBgColorLighter = darkenLighten('#232442', 90)
const headerBgColorTransparent = hexToRgbA('#232442', 0.4)

const GlobalStyle = createGlobalStyle`
// Extra small devices (portrait phones, less than 576px)
// No media query for xs since this is the default in Bootstrap
html {
  --page-color: ${pageColor};
  --page-color-dark: ${pageColorDark};
  --page-color-darker: ${pageColorDarker};
  --page-bg-color: ${pageBgColor};
  --page-bg-color-dark: ${pageBgColorDark};
  --page-bg-color-darker: ${pageBgColorDarker};
  --page-link-color: ${pageLinkColor};
  --page-link-color-darker: ${pageLinkColorDarker};
  --footer-color: #aeafff;
  --footer-bg-color: #232442;
  --footer-hover-color: #dcdcff;
  --header-bg-color: #232442;
  --header-bg-color-lighter: ${headerBgColorLighter};
  --header-bg-color-transparent: ${headerBgColorTransparent};
  --header-color: #ceceff;
  --secondary-bg-color-light: #ffbe97;
  --secondary-bg-color-dark: #d5895a;
  --secondary-color-dark: #973a00;
  --page-button-hover-bg-color: #80b580;
  --toggler-url-dark: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='MediumSlateBlue' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
  --toggler-url-light: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='MediumSlateBlue' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

body {
    color: var(--page-color) !important;
    background: var(--page-bg-color) !important;
    height: 100vh !important;
    font-family: 'Poppins' !important;
  }

  p {
      font-size: 1.2rem;
      color: var(--page-color);
  }

  pre {
      background-color: #302b2b;
      margin-left: -1.8rem;
      padding: 0.1rem .8rem;
  }

  code {
      color: var(--page-bg-color) !important;
      font-size: 1rem !important;
  }

  h1 {
    font-size: 2.2rem;
  }

  h2 {
    font-size: 1.8;
    margin-bottom: 2rem;
  }

  h2#referenzprojekte {
    font-size: 1rem;
    color: var(--footer-color);
    margin-left: 0.3rem;
  }

  h3 {
    font-size: 1.6rem;
    margin-bottom: 1.5rem;
    color: var(--page-color);
  }

  h4 {
    font-size: 1.4rem;
    margin-bottom: 1.2rem;
  }

  h5 {
    color: var(--page-color);
  }

  h6 {
    color: var(--page-color);
  }

      li {
      font-size: 1.2rem !important;
      color: var(--page-color);
    }

  li.language-switcher {
    font-size: 1.4rem;
  }

  em {
    font-size: .9rem;
  }

  a#basic-nav-dropdown {
    color: var(--page-bg-color);
    font-size: 1.2rem;
  }

  div.dropdown-menu.show {
    background-color: var(--page-bg-color-dark);
  }

  div.dropdown-menu.show a.nav-link {
    color: var(--header-bg-color);
  }

  // black background of whole image-slider component
  div.image-slider {
    background-color: black;
    padding-top: 3rem;
  }
  
  div.slider {
    margin-top: -1.5rem;
    margin-bottom: -0.2rem !important;
    padding-bottom: 3rem !important;
  }
  //
  
  // Small devices (landscape phones, 576px and up, col-sm)
  @media (min-width: 576px) {
    
  }
  // Medium devices (tablets, 768px and up, col-md)
  @media (min-width: 768px) {
    
  }
  // Large devices (desktops, 992px and up, col-lg)
  @media (min-width: 992px) {

    pre {
        margin-left: 0;
        padding: 1rem 3rem;
    }

    code {
        font-size: 1.6rem !important;
    }

    h2 {
    font-size: 2.6rem;
    margin-bottom: 2rem;
  }

      h2#referenzprojekte {
    font-size: 2rem;
    margin-left: -5rem;
  }

  h3 {
    font-size: 2.2rem;
    margin-bottom: 1.5rem;
  }

  h4 {
    font-size: 1.8rem;
    margin-bottom: 1.2rem;
  }
      
    p {
      font-size: 1.6rem;
    }

    li {
      font-size: 1.6rem !important;
    }

      // black background of whole image-slider component
  div.image-slider {
    padding-top: 2rem;
  }

  div.slider {
    margin-top: -3rem;
        margin-bottom: -10rem !important;
    padding-bottom: 8rem !important;
  }
  //
  }
  // Extra large devices (large desktops, 1200px and up, col-xl)
  @media (min-width: 1200px) {

}
`

export default GlobalStyle
