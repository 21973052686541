import styled from 'styled-components'
import { AnchorLink } from "gatsby-plugin-anchor-links"
import buttonStyles from '../components/styled/buttonStyles'

const StyledAnchorLinkButton = styled(AnchorLink)`

    // Small devices
    ${buttonStyles}

    // Small devices (landscape phones, 576px and up)
    @media (min-width: 576px) {
    }

    // Medium devices (tablets, 768px and up)
    @media (min-width: 768px) {
    }

    // Large devices (desktops, 992px and up)
    @media (min-width: 992px) {

    }

    // Extra large devices (large desktops, 1200px and up)
    @media (min-width: 1200px) {
        
    }
`;

export default StyledAnchorLinkButton;
